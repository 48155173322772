// import React, { useState } from 'react';
// import axios from 'axios';
// import './style.css';
// import toast, { Toaster } from 'react-hot-toast';
// import { creategallery } from '../../Utils/api';
// // import { createImage} from '../../Utils/api';


// const AddGallery = () => {
//   const [formData, setFormData] = useState({
//     image: null,
//   });

//   const [errors, setErrors] = useState({});

//   const inputHandler = (e) => {
//     const { name, value, files } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: files ? files[0] : value,
//     }));
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     if (!formData.image) newErrors.image = "Investigator image is required";
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) return;

//     const data = new FormData();
//      data.append('image', formData.image);

//     try {
//       const response = await axios.post(creategallery , data, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       console.log('Location image added:', response.data);
//       toast.success('Gallery Tab Image Added successfully!');
     
//       setFormData({
     
//         image: null,
//       });
//       setErrors({});
//     } catch (error) {
//       console.error('Error adding Investigator:', error);
//     }
//   };

//   return (
    
//     <div className='dataaddform'>
//     <Toaster/>
//       <h1 className='formheading'>Add Gallery Tab Image Form</h1>

//       <div className='row d-flex'></div>

//       <form onSubmit={handleSubmit}>
//         <div className="row">
//           <div className="col">
//             <label htmlFor="image" className="form-label">Gallery Tab Image</label>
//             <input
//               type="file"
//               className={`form-control ${errors.image ? 'is-invalid' : ''}`}
//               placeholder="Office Image"
//               onChange={inputHandler}
//               name="image"
//               id="image"
//             />
//             {errors.image && <div className="invalid-feedback">{errors.image}</div>}
//           </div>
//         </div>

      

    
      
//         <div className='row mt-3'>
//           <div className='col text-end'>
//             <button type="submit" className="btn btn-info">Submit</button>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default AddGallery;




// import React, { useState } from 'react';
// import axios from 'axios';
// import './style.css';
// import toast, { Toaster } from 'react-hot-toast';
// import { createImage } from '../../Utils/api';


// const AddGallery = () => {
//   const [formData, setFormData] = useState({
   
//    desc: "",
//     image: null,
//   });

//   const [errors, setErrors] = useState({});

//   const inputHandler = (e) => {
//     const { name, value, files } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: files ? files[0] : value,
//     }));
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     if (!formData.desc) newErrors.desc = "desc is required";
//     if (!formData.image) newErrors.image = "Investigator image is required";
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) return;

//     const data = new FormData();
//    data.append('desc', formData.desc);
//     data.append('image', formData.image);

//     try {
//       const response = await axios.post(createImage, data, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       console.log('Investigator added:', response.data);
//       toast.success('Investigator Added successfully!');
     
//       setFormData({
      
//         desc: "",
//         image: null,
//       });
//       setErrors({});
//     } catch (error) {
//       console.error('Error adding Investigator:', error);
//     }
//   };

//   return (
    
//     <div className='dataaddform'>
//     <Toaster/>
//       <h1 className='formheading'>Add Gallery Image</h1>

//       <div className='row d-flex'></div>

//       <form onSubmit={handleSubmit}>
//         <div className="row">
//           <div className="col">
//             <label htmlFor="image" className="form-label">Investigator Image</label>
//             <input
//               type="file"
//               className={`form-control ${errors.image ? 'is-invalid' : ''}`}
//               placeholder="Location Name"
//               onChange={inputHandler}
//               name="image"
//               id="image"
//             />
//             {errors.image && <div className="invalid-feedback">{errors.image}</div>}
//           </div>
//         </div>

 

//         <div className='row mt-4'>
//           <div className="col">
//             <label htmlFor="desc" className="form-label">Investigators Position</label>
//             <input
//               type="text"
//               className={`form-control ${errors.desc ? 'is-invalid' : ''}`}
//               placeholder="Investigators Position"
//               onChange={inputHandler}
//               id="desc"
//               name="desc"
//               value={formData.desc}
//             />
//             {errors.desc && <div className="invalid-feedback">{errors.desc}</div>}
//           </div>
//         </div>

      
//         <div className='row mt-3'>
//           <div className='col text-end'>
//             <button type="submit" className="btn btn-info">Submit</button>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default AddGallery;















// import React, { useState } from 'react';
// import axios from 'axios';
// import './style.css';
// import toast, { Toaster } from 'react-hot-toast';
// import { createImage } from '../../Utils/api';

// const AddGallery = () => {
//   const [formData, setFormData] = useState({
//     desc: "",
//     image: null,
//   });
//   const [errors, setErrors] = useState({});
//   const [isSubmitting, setIsSubmitting] = useState(false); // to handle multiple submissions

//   const inputHandler = (e) => {
//     const { name, value, files } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: files ? files[0] : value,
//     }));
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     if (!formData.desc) newErrors.desc = "Image description is required";
//     if (!formData.image) newErrors.image = "Gallery image is required";
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) return;

//     const data = new FormData();
//     data.append('desc', formData.desc);
//     data.append('image', formData.image);

//     setIsSubmitting(true); // Disable button on submit

//     try {
//       const response = await axios.post('http://localhost:4000/gallery/createImage', data, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       console.log("response",response);
      
//       toast.success('Image added successfully!');
//       setFormData({
//         desc: "",
//         image: null,
//       });
//       setErrors({});
//     } catch (error) {
//       toast.error('Error adding image, please try again.');
//       console.error('Error adding image:', error);
//     } finally {
//       setIsSubmitting(false); // Re-enable button after response
//     }
//   };

//   return (
//     <div className='dataaddform'>
//       <Toaster />
//       <h1 className='formheading'>Add Gallery Image</h1>

//       <form onSubmit={handleSubmit}>
//         <div className="row">
//           <div className="col">
//             <label htmlFor="image" className="form-label">Gallery Image</label>
//             <input
//               type="file"
//               className={`form-control ${errors.image ? 'is-invalid' : ''}`}
//               onChange={inputHandler}
//               name="image"
//               id="image"
//             />
//             {errors.image && <div className="invalid-feedback">{errors.image}</div>}
//           </div>
//         </div>

//         <div className='row mt-4'>
//           <div className="col">
//             <label htmlFor="desc" className="form-label">Image Description</label>
//             <input
//               type="text"
//               className={`form-control ${errors.desc ? 'is-invalid' : ''}`}
//               placeholder="Image Description"
//               onChange={inputHandler}
//               id="desc"
//               name="desc"
//               value={formData.desc}
//             />
//             {errors.desc && <div className="invalid-feedback">{errors.desc}</div>}
//           </div>
//         </div>

//         <div className='row mt-3'>
//           <div className='col text-end'>
//             <button type="submit" className="btn btn-info" disabled={isSubmitting}>
//               {isSubmitting ? 'Submitting...' : 'Submit'}
//             </button>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default AddGallery;

















import React, { useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const AddGallery = () => {
  const [formData, setFormData] = useState({
    desc: "",
    image: null,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const inputHandler = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files ? files[0] : value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.desc) newErrors.desc = "Image description is required";
    if (!formData.image) newErrors.image = "Gallery image is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = new FormData();
    data.append('desc', formData.desc);
    data.append('image', formData.image);

    setIsSubmitting(true);

    try {
      const response = await axios.post('http://localhost:4000/gallery/createImage', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("response", response);

      toast.success('Image added successfully!');
      setFormData({
        desc: "",
        image: null,
      });
      setErrors({});
    } catch (error) {
      console.error('Error adding image:', error.response ? error.response.data : error.message);
      toast.error('Error adding image, please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='dataaddform'>
      <Toaster />
      <h1 className='formheading'>Add Gallery Image</h1>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <label htmlFor="image" className="form-label">Gallery Image</label>
            <input
              type="file"
              className={`form-control ${errors.image ? 'is-invalid' : ''}`}
              onChange={inputHandler}
              name="image"
              id="image"
              accept="image/*"
            />
            {errors.image && <div className="invalid-feedback">{errors.image}</div>}
          </div>
        </div>

        <div className='row mt-4'>
          <div className="col">
            <label htmlFor="desc" className="form-label">Image Description</label>
            <input
              type="text"
              className={`form-control ${errors.desc ? 'is-invalid' : ''}`}
              placeholder="Image Description"
              onChange={inputHandler}
              id="desc"
              name="desc"
              value={formData.desc}
            />
            {errors.desc && <div className="invalid-feedback">{errors.desc}</div>}
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col text-end'>
            <button type="submit" className="btn btn-info" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddGallery;
