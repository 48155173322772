// import React, { useEffect, useState } from 'react';
// import axios from 'axios'; // Ensure axios is imported
// import '../About/About.css';
// import './Gallery.css';
// import { baseUrl, getALLgallery, getAllImage } from '../../Utils/api'; // Assuming baseUrl is your API base URL

// const Gallery = () => {
//     const [imageUrls, setImages] = useState([]); // State to hold image URLs
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);
//     useEffect(() => {
//         const fetchImages = async () => {
//             try {
//                 const response = await axios.get(getALLgallery);
//                 const imageUrls = response.data.data.map(study => `${baseUrl}${study.image}`); // Append baseUrl to image paths
//                 setImages(imageUrls); // Set the state with the array of image URLs
//             } catch (error) {
//                 console.log('Error fetching data', error);
//             }
//         };

//         fetchImages();
//     }, []); // Empty dependency array means this runs once on mount

//     return (
//       <div className='container-fluid'>
//       <div className='row '>  
//         <div className='col aboutus__bg aboutus_title'>
//             <h2 className='fs-1 fw-bold text-light text-center'>Our Gallery</h2>
//         </div>
//       </div>

//         <div className="image-gallery bg-gray-200">
//             {imageUrls.map((url, index) => ( // Map through the image URLs to display each image
//                 <img src={url} alt={`Gallery Item ${index + 1}`} key={index} className="image-gallery__item" />
//             ))}
//         </div>
//         </div>
//     );
// };

// export default Gallery;












import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import '../About/About.css';
import './Gallery.css';
import { baseUrl, getALLgallery } from '../../Utils/api';

const Gallery = () => {
    const [galleryImages, setGalleryImages] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(getALLgallery);
                const imageData = response.data.data.map(study => ({
                    url: `${baseUrl}${study.image}`, // Build the full URL for the image
                    desc: study.desc || study.desc || 'No description available' // Handle both 'description' and 'desc', with a fallback
                }));
                setGalleryImages(imageData); // Set the state with the array of image data
            } catch (error) {
                console.log('Error fetching data', error);
            }
        };
    
        fetchImages();
    }, []);




    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col aboutus__bg aboutus_title'>
                    <h2 className='fs-1 fw-bold text-light text-center'>Our Gallery</h2>
                </div>
            </div>

            <div className="image-gallery bg-gray-200">
                {galleryImages.map((image, index) => ( // Map through the images to display each image with title/description
                    <div key={index} className="image-gallery__item">
                        <img src={image.url} alt={`Gallery Item ${index + 1}`} className="image-gallery__img" />
                        <div className="image-overlay">
                         
                            <p>{image.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;












// import React, { useEffect, useState } from 'react';
// import axios from 'axios'; 
// import '../About/About.css';
// import './Gallery.css';
// import { baseUrl, getALLgallery } from '../../Utils/api';

// const Gallery = () => {
//     const [galleryImages, setGalleryImages] = useState([]);

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     useEffect(() => {
//         const fetchImages = async () => {
//             try {
//                 const response = await axios.get(getALLgallery);
//                 const imageData = response.data.data.map(study => ({
//                     url: `${baseUrl}${study.image}`, // Build the full URL for the image
//                     title: study.title || 'Untitled', // Default to 'Untitled' if the title is missing
//                     description: study.description || study.desc || 'No description available' // Handle both 'description' and 'desc', with a fallback
//                 }));
//                 setGalleryImages(imageData); // Set the state with the array of image data
//             } catch (error) {
//                 console.log('Error fetching data', error);
//             }
//         };
    
//         fetchImages();
//     }, []);


//     console.log("data",Image);
    

//     return (
//         <div className='container-fluid'>
//             <div className='row'>
//                 <div className='col aboutus__bg aboutus_title'>
//                     <h2 className='fs-1 fw-bold text-light text-center'>Our Gallery</h2>
//                 </div>
//             </div>

//             <div className="image-gallery bg-gray-200">
//                 {galleryImages.map((image, index) => ( // Map through the images to display each image with title/description
//                     <div key={index} className="image-gallery__item">
//                         <img src={image.url} alt={`Gallery Item ${index + 1}`} className="image-gallery__img" />
//                         <div className="image-overlay">
                          
//                             <p>{image.description}</p>
//                         </div>
//                     </div>
//                 ))}


// {galleryImages.map((image, index) => (
//     <div key={index}>
//         <img src={image.url} alt={image.title} />
//         <h3>{image.title}</h3>
//         <p>{image.description}</p>
//     </div>
// ))}

//             </div>
//         </div>
//     );
// };

// export default Gallery;
