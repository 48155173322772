import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './style.css';
import toast, { Toaster } from 'react-hot-toast';
import { baseUrl, getGalleryBYID, getImageBYID, updategallery, updateImage } from '../../Utils/api';

const UpdateGallery = () => {
  const { id } = useParams();
  
  const [formData, setFormData] = useState({
    desc: "", // Field for the investigator's name
    image: null // Field for the new image file
  });

  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const [existingImage, setExistingImage] = useState(null); // State for existing image URL
  const [errors, setErrors] = useState({}); // Add error state for form validation

  useEffect(() => {
    // Fetch existing data for the given ID and set the form state
    const fetchData = async () => {
      try {
        const response = await axios.get(getGalleryBYID + id);
        const data = response.data.data;

        console.log('Fetched data:', data); // Debugging: Log fetched data

        setFormData({
          desc: data.desc || "", // Set the investigator's name
          image: null // Initialize image as null
        });
        setExistingImage(data.image); // Set existing image URL
        setIsLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Ensure loading state is turned off even if there's an error
      }
    };

    fetchData();
  }, [id]);

  const inputHandler = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: files ? files[0] : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
 
    data.append('desc', formData.desc); // Append the updated name (desc)
    if (formData.image) {
      data.append('image', formData.image); // Append the new image if it exists
    }

    try {
      const response = await axios.put(updategallery + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Investigator updated:', response.data);
      toast.success('Investigator updated successfully!');
    } catch (error) {
      console.error('Error updating investigator:', error);
      toast.error('Failed to update investigator.');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='dataaddform'>
      <Toaster/>
      <h1 className='formheading'>Update Gallery Form</h1>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <label htmlFor="image" className="form-label">Investigator Image</label>
            {existingImage && (
              <div>
                <img src={baseUrl + existingImage} alt="Current Investigator" style={{ width: '200px', height: 'auto' }} />
              </div>
            )}
            <input
              type="file"
              className={`form-control ${errors.image ? 'is-invalid' : ''}`}
              placeholder="Investigator Image"
              onChange={inputHandler}
              name="image"
              id="image"
            />
            {errors.image && <div className="invalid-feedback">{errors.image}</div>}
          </div>
        </div>

        <div className='row mt-4'>
          <div className="col">
            <label htmlFor="desc" className="form-label">Investigator Name</label>
            <input
              type="text"
              className={`form-control ${errors.desc ? 'is-invalid' : ''}`}
              placeholder="Investigator Name"
              onChange={inputHandler}
              id="desc"
              name="desc" // Corrected to match the state property 'desc'
              value={formData.desc} // Controlled input for investigator's name
            />
            {errors.desc && <div className="invalid-feedback">{errors.desc}</div>}
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col text-end'>
            <button type="submit" className="btn btn-info">Update</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateGallery;
