import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Recent from '../src/Admin/AdminPages/AddRecent';
import InfoRecent from './Admin/AdminPages/InfoRecent';
import UpdateRecent from './Admin/AdminPages/UpdateRecent'
import RecentDetails from './Pages/Blog/Recentdetails';
import UpcomingEvents from './Pages/UpcomingEvents/UpcomingEvents';
import Gallery from './Pages/Gallery/Gallery';
import EventDetails from './Pages/UpcomingEvents/EventDetails';
import AddGallery from './Admin/AdminPages/AddGallery';
import InfoGallery from './Admin/AdminPages/InfoGallery';
import UpdateGallery from './Admin/AdminPages/UpdateGallery';

// Lazy loading components
const Error = lazy(() => import('./Component/Error/Error'));
const Layout = lazy(() => import('./Pages/Layout'));
const Home = lazy(() => import('./Pages/Home/Home'));
const Aboutus = lazy(() => import('./Pages/About/Aboutus'));
const Coreteam = lazy(() => import('./Pages/About/Coreteam'));
const Testimonial = lazy(() => import('./Pages/About/Testimonial'));
const Studyparticipants = lazy(() => import('./Pages/StudyParticipants/Studyparticipants'));
const Allstudies = lazy(() => import('./Pages/Studies/Allstudies'));
const StudiesDetail = lazy(() => import('./Component/Studies/StudiesDetail'));
const Sponsors = lazy(() => import('./Pages/Sponsors/Sponsors&cors'));
const LancasterLocation = lazy(() => import('./Pages/Location/LancasterLocation'));
const Location = lazy(() => import('./Pages/About/Location'));
const Blog = lazy(() => import('./Pages/Blog/Blog'));
const Login = lazy(() => import('./Component/Login/login'));
const Dashboard = lazy(() => import('./Admin/Dashboard'));
const Auth = lazy(() => import('./Component/Login/Auth'));
const Sidebar = lazy(() => import('./Admin/AdminComponent/Sidebar'));
const AddStudies = lazy(() => import('./Admin/AdminPages/AddStudies'));
const AddBlog = lazy(() => import('./Admin/AdminPages/AddBlog'));
const InfoBlog = lazy(() => import('./Admin/AdminPages/InfoBlog'));
const InfoStudies = lazy(() => import('./Admin/AdminPages/InfoStudies'));
const UpdateBlog = lazy(() => import('./Admin/AdminPages/UpdateBlog'));
const UpdateStudies = lazy(() => import('./Admin/AdminPages/UpdateStudies'));
const Blogdetails = lazy(() => import('./Pages/Blog/Blogdetails'));
const InfoAddInvestigators = lazy(() => import('./Admin/AdminPages/InfoAddInvestigators'));
const AddInvestigators = lazy(() => import('./Admin/AdminPages/AddInvestigators'));
const UpdateInvestigators = lazy(() => import('./Admin/AdminPages/UpdateInvesigator'));
const Addimage = lazy(() => import('./Admin/AdminPages/Addimage'));
const InfoImage = lazy(() => import('./Admin/AdminPages/Imfoimage'));
const AddEvent = lazy(() => import('./Admin/AdminPages/Addevent'));
const InfoEvents = lazy(() => import('./Admin/AdminPages/InfoEvents'));
const UpdateEvent = lazy(() => import('./Admin/AdminPages/UpdateEvent'));

const Thankyou = lazy(() => import('./Component/Thankyou/Thankyou'))


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Layout />
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<div>Loading...</div>}>
        <Error />
      </Suspense>
    ),
    children: [
      { index: true, element: <Suspense fallback={<h1>Loading...</h1>}><Home /></Suspense> },
      { path: "/about", element: <Suspense fallback={<h1>Loading...</h1>}><Aboutus /></Suspense> },
      { path: "/coreteam", element: <Suspense fallback={<h1>Loading...</h1>}><Coreteam /></Suspense> },
      { path: "/testimonial", element: <Suspense fallback={<h1>Loading...</h1>}><Testimonial /></Suspense> },
      { path: "/location", element: <Suspense fallback={<h1>Loading...</h1>}><Location /></Suspense> },
      { path: "/studyparticipants", element: <Suspense fallback={<h1>Loading...</h1>}><Studyparticipants /></Suspense> },
      { path: "/allstudies", element: <Suspense fallback={<h1>Loading...</h1>}><Allstudies /></Suspense> },
      { path: "/detail/:id", element: <Suspense fallback={<h1>Loading...</h1>}><StudiesDetail /></Suspense> },
      { path: "/sponsors", element: <Suspense fallback={<h1>Loading...</h1>}><Sponsors /></Suspense> },
      { path: "/LLocation", element: <Suspense fallback={<h1>Loading...</h1>}><LancasterLocation /></Suspense> },
      { path: "/blog", element: <Suspense fallback={<h1>Loading...</h1>}><Blog /></Suspense> },
      { path: "/blogdetail/:title", element: <Suspense fallback={<h1>Loading...</h1>}><Blogdetails /></Suspense> },
      { path: "/recentdetail/:title", element: <Suspense fallback={<h1>Loading...</h1>}><RecentDetails/></Suspense> },
      { path: "/thankyou", element: <Suspense fallback={<h1>Loading...</h1>}><Thankyou/> </Suspense> },
      { path: "/upcoming", element: <Suspense fallback={<h1>Loading...</h1>}><UpcomingEvents/></Suspense> },
      { path: "/eventdetail/:id", element: <Suspense fallback={<h1>Loading...</h1>}><EventDetails/></Suspense> },

      { path: "/gallery", element: <Suspense fallback={<h1>Loading...</h1>}><Gallery/></Suspense> },
      
    ]

  },
  {
    path: "/admin",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Login />
      </Suspense>
    )
  },
  {
    path: "/Dashboard",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <Dashboard />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Add Studies",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <AddStudies />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Add Blog",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <AddBlog />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Info Blog",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <InfoBlog />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Info Studies",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <InfoStudies />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Update Studies/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <UpdateStudies />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Update Blog/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <UpdateBlog />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Info Investigators",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <InfoAddInvestigators />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Add Investigator",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <AddInvestigators />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Update Investigators/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <UpdateInvestigators />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Add Image",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <Addimage />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/Info Image",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <InfoImage />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/add-event",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <AddEvent />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/info-events",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <InfoEvents />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/UpdateEvent/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <UpdateEvent />
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/add-Recent",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <Recent/>
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },

  {
    path: "/info-recent",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <InfoRecent/>
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },


  {
    path: "/UpdateRecent/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
           <UpdateRecent/>
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/add-Gallery",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <AddGallery/>
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },

  {
    path: "/info-Gallery",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
      <InfoGallery/>
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
  {
    path: "/UpdateGallery/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Auth>
          <Sidebar>
            <UpdateGallery/>
          </Sidebar>
        </Auth>
      </Suspense>
    )
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <RouterProvider router={router} />
  </div>
);
